.divStyle {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1;
  position: relative;
}