.slider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-border {
  margin-bottom: 0px;
  margin-top: 5px;
}

.img-header {
  color: var(--buic-foreground-muted);
  font-weight: 600;
  margin-left: 19px;
  padding-bottom: 0px;
  margin-bottom: 2px;
  font-size: 12px;
}

.info-panel .header {
  margin-bottom: 5px;
}

.img-gallary {
  margin-top: 5px;
  font-weight: 600;
  margin-left: 19px;
  cursor: pointer;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 25px;
  z-index: 10;
  font-size: 2rem;
  color: white;
  user-select: none;
  cursor: pointer;
  z-index: 10000;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 25px;
  z-index: 10;
  font-size: 2rem;
  color: white;
  user-select: none;
  cursor: pointer;
  z-index: 10000;
}

.close-icon {
  position: fixed;
  top: 0;
  right: 4px;
  z-index: 10000000;
  font-size: 2rem;
  color: black;
  user-select: none;
  cursor: pointer;
}

.imgL {
  width: 100%;
  height: 100%;
}

.imodel-viewport-dialog-container, .react-transform-wrapper, .react-transform-component {
  height: 100% !important;
  width: 100% !important;
}

#container {
  height: 100%;
}

.push-btn {
  text-decoration: none;
  border: none;
  padding: 4px;
  width: 80%;
  font-size: 15px;
  background-color: #199fd8;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  transition: 0.2s all;
  margin-top: 3px;
  margin-bottom: 3px;
}

/* Adding transformation when the button is active */
.push-btn:active {
  transform: scale(0.98);
  box-shadow: 2px 1px 15px 0.5px rgba(0, 0, 0, 0.24);
}

.btn-wrapper {
  text-align: center;
  width: 100%;
}