.sidebar {
  max-width: 175px;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;
}

.sidebar-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expanded-arrow-icon {
  position: inherit;
  bottom: 11px;
}

.sidebar-item-content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  align-items: center;
  width: 100%;
}

.bnt-hc-side-navigation-expand-btn {
  cursor: pointer;
  list-style: none;
}
.bnt-hc-side-navigation-expand-btn > a {
  display: flex;
  align-self: center;
  justify-content: center;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 0 none transparent;
  margin: 0px;
}
.bnt-hc-side-navigation-expand-btn > a > i {
  font-size: 8px;
}

.sidebar-item-icon {
  margin-right: 6px;
}

.sidebar-item-text {
  width: 100%;
  color: black;
  font-size: 12px;
  text-align: center;
}

.sidebar-item-expand-arrow {
  font-size: 1.2rem !important;
  color: black;
}

.sidebar-item-expand-arrow-expanded {
  color: #09bb12;
  font-weight: bold;
}

.MuiListItem-button.active {
  filter: invert(0.39) sepia(1) saturate(17) hue-rotate(169.2deg) brightness(0.85);
}

.MuiListItem-gutters {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.sidebar-item-icon {
  margin-right: 0px !important;
}