.imageViewModel {
  pointer-events: auto;
}

.slider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-border {
  margin-bottom: 0px;
  margin-top: 5px;
}

.img-header {
  color: var(--buic-foreground-muted);
  font-weight: 600;
  margin-left: 19px;
  padding-bottom: 0px;
  margin-bottom: 2px;
  font-size: 12px;
}

.info-panel .header {
  margin-bottom: 5px;
}

.img-gallary {
  margin-top: 5px;
  font-weight: 600;
  margin-left: 19px;
  cursor: pointer;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 25px;
  z-index: 10;
  font-size: 2rem;
  color: white;
  user-select: none;
  cursor: pointer;
  z-index: 10000;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 25px;
  z-index: 10;
  font-size: 2rem;
  color: white;
  user-select: none;
  cursor: pointer;
  z-index: 10000;
}

.close-icon {
  position: fixed;
  top: 0;
  right: 4px;
  z-index: 10000000;
  font-size: 2rem;
  color: black;
  user-select: none;
  cursor: pointer;
}

.imgL {
  width: 100%;
  height: 100%;
}

.imodel-viewport-dialog-container {
  height: 100% !important;
  width: 100% !important;
}

#container {
  height: 100%;
}

.push-btn {
  text-decoration: none;
  border: none;
  padding: 4px;
  width: 80%;
  font-size: 15px;
  background-color: #199fd8;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  transition: 0.2s all;
  margin-top: 3px;
  margin-bottom: 3px;
}

/* Adding transformation when the button is active */
.push-btn:active {
  transform: scale(0.98);
  box-shadow: 2px 1px 15px 0.5px rgba(0, 0, 0, 0.24);
}

.btn-wrapper {
  text-align: center;
  width: 100%;
}

.annotation {
  font-weight: bold;
  text-align: center;
  font-size: small;
  width: 30px;
  height: 30px;
  background-color: black;
  color: white;
  border: 5px solid white;
  border-radius: 38px;
  font-style: italic;
  cursor: pointer;
}

.selected-annotation {
  font-weight: bold;
  text-align: center;
  font-size: small;
  width: 30px;
  height: 30px;
  background-color: rgb(39, 32, 253);
  color: white;
  border: 5px solid white;
  border-radius: 38px;
  font-style: italic;
  cursor: pointer;
}

.annotation:hover {
  cursor: pointer;
}

.low-defect {
  font-weight: bold;
  text-align: center;
  font-size: small;
  width: 30px;
  height: 30px;
  background-color: rgb(232, 220, 0);
  color: white;
  border: 5px solid white;
  border-radius: 38px;
  font-style: italic;
  cursor: pointer;
}

.low-defect:hover {
  cursor: pointer;
}

.medium-defect {
  font-weight: bold;
  text-align: center;
  font-size: small;
  width: 30px;
  height: 30px;
  background-color: rgb(255, 132, 0);
  color: white;
  border: 5px solid white;
  border-radius: 38px;
  font-style: italic;
  cursor: pointer;
}

.medium-defect:hover {
  cursor: pointer;
}

.high-defect {
  font-weight: bold;
  text-align: center;
  font-size: small;
  width: 30px;
  height: 30px;
  background-color: rgb(255, 0, 0);
  color: white;
  border: 5px solid white;
  border-radius: 38px;
  font-style: italic;
  cursor: pointer;
}

.high-defect:hover {
  cursor: pointer;
}

.annotation-new {
  font-weight: bold;
  text-align: center;
  font-size: small;
  width: 30px;
  height: 30px;
  background-color: rgb(4, 255, 0);
  color: white;
  border: 5px solid white;
  border-radius: 38px;
  font-style: italic;
}

.compass-angle {
  background-color: rgba(255, 255, 255, 0.546);
  width: 30px;
  height: 30px;
  position: absolute;
  right: 25px;
  bottom: 25px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 5px;
  text-align: center;
}

.plan-compass {
  right: 20px;
  bottom: 20px;
  /* box-shadow: 2px 2px 5px white; */
  filter: drop-shadow(rgb(255, 255, 255) 0px 0px 2px);
}

.image-input {
  background-color: #fff;
  background-image: none;
  border: 1px solid #c7ccd1;
  border-radius: 3px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.0784313725);
  color: #2a2f34;
  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  margin: 3px 10px 14px 0;
  padding: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.image-ins-label {
  width: 50%;
  font-weight: 600;
  align-items: center;
  align-self: center;
}

#panoramicModal ._iui3163-input-label[data-iui-disabled=true] {
  color: white;
}

#panoramicModal ._iui3163-input:disabled {
  background-color: #D6D0CF;
}

.input-date {
  background-color: #fff;
  background-image: none;
  border: 1px solid #c7ccd1;
  border-radius: 3px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.0784313725);
  color: #2a2f34;
  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  margin: 3px 10px 8px 0;
  padding: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}