.padded-content {
  padding: 8px;
}

.custom-item {
  display: flex;
  flex-direction: column;
  max-width: 360px;
}

.equip-search-checkbox label {
  display: flex;
  font-weight: bold;
  align-items: flex-start;
}