.iui-root,
[data-iui-theme] {
  --iui-size-3xs: 0.125rem;
  --iui-size-2xs: 0.25rem;
  --iui-size-xs: 0.5rem;
  --iui-size-s: 0.75rem;
  --iui-size-m: 1rem;
  --iui-size-l: 1.5rem;
  --iui-size-xl: 2rem;
  --iui-size-2xl: 4rem;
  --iui-size-3xl: 6rem;
  --iui-component-height-small: calc(var(--iui-size-s) * 2);
  --iui-component-height: calc(var(--iui-size-s) * 3);
  --iui-component-height-large: calc(var(--iui-size-s) * 4);
  --iui-border-radius-1: 4px;
  --iui-border-radius-round: 9999px;
  --iui-font-sans: "Noto Sans", "Open Sans", system-ui, sans-serif;
  --iui-font-mono: "Noto Sans Mono", ui-monospace, "Segoe UI Mono", Consolas, "Roboto Mono", monospace;
  --iui-font-size-0: 0.75rem;
  --iui-font-size-1: 0.875rem;
  --iui-font-size-2: 1rem;
  --iui-font-size-3: 1.125rem;
  --iui-font-size-4: 1.5rem;
  --iui-font-size-5: 2rem;
  --iui-font-weight-light: 300;
  --iui-font-weight-normal: 400;
  --iui-font-weight-semibold: 600;
  --iui-font-weight-bold: 700;
  --iui-shadow-0: 0;
  --iui-shadow-1: 0 1px 5px var(--iui-shadow-color);
  --iui-shadow-2: 0 1px 10px var(--iui-shadow-color);
  --iui-shadow-3: 0 3px 14px var(--iui-shadow-color);
  --iui-shadow-4: 0 6px 30px var(--iui-shadow-color);
  --iui-shadow-5: 0 9px 46px var(--iui-shadow-color);
  --iui-duration-0: 0s;
  --iui-duration-1: 0.2s;
  --iui-duration-2: 0.4s;
  --iui-duration-3: 0.8s;
  --iui-color-background-skyblue: #b5e1f2;
  --iui-color-background-celery: #c3d57c;
  --iui-color-background-froly: #fbafad;
  --iui-color-background-steelblue: #9cbdd8;
  --iui-color-background-sunglow: #ffd166;
  --iui-color-background-seabuckthorn: #f9b371;
  --iui-color-background-montecarlo: #99d6d2;
  --iui-color-background-poloblue: #abc3de;
  --iui-color-background-bouquet: #cbb3c9;
  --iui-color-background-ash: #dedbd3;
  --iui-color-background-oak: #ceb5a1;
  --iui-color-white: white;
}

.iui-root,
[data-iui-theme=light] {
  --iui-opacity-1: 1;
  --iui-opacity-2: 0.8;
  --iui-opacity-3: 0.6;
  --iui-opacity-4: 0.4;
  --iui-opacity-5: 0.2;
  --iui-opacity-6: 0.1;
  --iui-color-background: white;
  --iui-color-background-zebra: #fbfbfc;
  --iui-color-background-hover: #f7f8f8;
  --iui-color-background-backdrop: #eef0f1;
  --iui-color-background-backdrop-hover: #e6e9ea;
  --iui-color-background-disabled: #dde1e3;
  --iui-color-background-accent: var(--iui-color-background-informational);
  --iui-color-background-accent-hover: var(
    --iui-color-background-informational-hover
  );
  --iui-color-background-accent-muted: var(
    --iui-color-background-informational-muted
  );
  --iui-color-background-informational: #0071b8;
  --iui-color-background-informational-hover: #00568f;
  --iui-color-background-informational-muted: #e4f3fc;
  --iui-color-background-positive: #157e11;
  --iui-color-background-positive-hover: #0d610a;
  --iui-color-background-positive-muted: #e7f4e6;
  --iui-color-background-warning: #a05c08;
  --iui-color-background-warning-hover: #7c4604;
  --iui-color-background-warning-muted: #f9f1e6;
  --iui-color-background-negative: #db0a0a;
  --iui-color-background-negative-hover: #a80505;
  --iui-color-background-negative-muted: #ffebeb;
  --iui-color-background-transparent-hover: hsla(0deg, 0%, 0%, 0.06);
  --iui-color-border-subtle: #c4cbcf;
  --iui-color-border: #b3bcc1;
  --iui-color-border-foreground: #8f8f8f;
  --iui-color-border-foreground-hover: #6b6b6b;
  --iui-color-border-disabled: #c4cbcf;
  --iui-color-border-accent: var(--iui-color-border-informational);
  --iui-color-border-informational: #0071b8;
  --iui-color-border-positive: #157e11;
  --iui-color-border-warning: #7a4606;
  --iui-color-border-negative: #db0a0a;
  --iui-color-icon: #363636;
  --iui-color-icon-hover: #242424;
  --iui-color-icon-muted: #7d7d7d;
  --iui-color-icon-muted-hover: #6b6b6b;
  --iui-color-icon-disabled: #8f8f8f;
  --iui-color-icon-accent: var(--iui-color-icon-informational);
  --iui-color-icon-informational: #008ae0;
  --iui-color-icon-positive: #1c9b17;
  --iui-color-icon-warning: #a05c08;
  --iui-color-icon-negative: #fd3535;
  --iui-color-text: #242424;
  --iui-color-text-hover: #121212;
  --iui-color-text-muted: #595959;
  --iui-color-text-placeholder: #747474;
  --iui-color-text-disabled: #7d7d7d;
  --iui-color-text-accent: var(--iui-color-text-informational);
  --iui-color-text-accent-hover: var(--iui-color-text-informational-hover);
  --iui-color-text-informational: #0071b8;
  --iui-color-text-informational-hover: #00568f;
  --iui-color-text-positive: #157e11;
  --iui-color-text-positive-hover: #0d610a;
  --iui-color-text-warning: #7a4606;
  --iui-color-text-warning-hover: #552f02;
  --iui-color-text-negative: #db0a0a;
  --iui-color-text-negative-hover: #a80505;
  --iui-color-background-hsl: 203deg 10% 100%;
  --iui-color-foreground-hsl: 0deg 0% 7%;
  --iui-color-accent-hsl: var(--iui-color-informational-hsl);
  --iui-color-informational-hsl: 203deg 100% 44%;
  --iui-color-positive-hsl: 118deg 74% 35%;
  --iui-color-warning-hsl: 33deg 90% 33%;
  --iui-color-negative-hsl: 0deg 98% 60%;
  --iui-shadow-color: hsl(0 0% 0%/var(--iui-opacity-5));
}

.iui-root[data-iui-contrast=high],
[data-iui-theme=light][data-iui-contrast=high] {
  --iui-opacity-1: 1;
  --iui-opacity-2: 0.8;
  --iui-opacity-3: 0.6;
  --iui-opacity-4: 0.4;
  --iui-opacity-5: 0.2;
  --iui-opacity-6: 0.1;
  --iui-color-background: white;
  --iui-color-background-zebra: #fafafa;
  --iui-color-background-hover: #f4f5f5;
  --iui-color-background-backdrop: #eaebec;
  --iui-color-background-backdrop-hover: #dfe1e2;
  --iui-color-background-disabled: #d4d7d8;
  --iui-color-background-accent: var(--iui-color-background-informational);
  --iui-color-background-accent-hover: var(--iui-color-background-informational-hover);
  --iui-color-background-accent-muted: var(--iui-color-background-informational-muted);
  --iui-color-background-informational: #00568f;
  --iui-color-background-informational-hover: #003c66;
  --iui-color-background-informational-muted: #e5eff5;
  --iui-color-background-positive: #0d610a;
  --iui-color-background-positive-hover: #074205;
  --iui-color-background-positive-muted: #e7f0e6;
  --iui-color-background-warning: #7c4604;
  --iui-color-background-warning-hover: #563001;
  --iui-color-background-warning-muted: #f3eee7;
  --iui-color-background-negative: #a80505;
  --iui-color-background-negative-hover: #740202;
  --iui-color-background-negative-muted: #ffebeb;
  --iui-color-background-transparent-hover: hsla(0deg, 0%, 0%, 0.08);
  --iui-color-border-subtle: #b4b8bb;
  --iui-color-border: #9fa4a8;
  --iui-color-border-foreground: #7a7a7a;
  --iui-color-border-foreground-hover: #5c5c5c;
  --iui-color-border-disabled: #b4b8bb;
  --iui-color-border-accent: var(--iui-color-border-informational);
  --iui-color-border-informational: #004f80;
  --iui-color-border-positive: #0d560b;
  --iui-color-border-warning: #583304;
  --iui-color-border-negative: #9c0707;
  --iui-color-icon: #2e2e2e;
  --iui-color-icon-hover: #1f1f1f;
  --iui-color-icon-muted: #5c5c5c;
  --iui-color-icon-muted-hover: #4d4d4d;
  --iui-color-icon-disabled: #7a7a7a;
  --iui-color-icon-accent: var(--iui-color-icon-informational);
  --iui-color-icon-informational: #00619e;
  --iui-color-icon-positive: #116c0e;
  --iui-color-icon-warning: #754306;
  --iui-color-icon-negative: #bf0808;
  --iui-color-text: #1f1f1f;
  --iui-color-text-hover: #0f0f0f;
  --iui-color-text-muted: #4d4d4d;
  --iui-color-text-disabled: #6b6b6b;
  --iui-color-text-accent: var(--iui-color-text-informational);
  --iui-color-text-accent-hover: var(--iui-color-text-informational-hover);
  --iui-color-text-informational: #004f80;
  --iui-color-text-informational-hover: #002c47;
  --iui-color-text-positive: #0d560b;
  --iui-color-text-positive-hover: #083206;
  --iui-color-text-warning: #583304;
  --iui-color-text-warning-hover: #2c1902;
  --iui-color-text-negative: #9c0707;
  --iui-color-text-negative-hover: #580404;
  --iui-color-background-hsl: 203deg 5% 100%;
  --iui-color-foreground-hsl: 0deg 0% 6%;
  --iui-color-accent-hsl: var(--iui-color-informational-hsl);
  --iui-color-informational-hsl: 203deg 100% 31%;
  --iui-color-positive-hsl: 118deg 77% 24%;
  --iui-color-warning-hsl: 33deg 91% 24%;
  --iui-color-negative-hsl: 0deg 92% 39%;
}

[data-iui-theme=dark] {
  --iui-opacity-1: 1;
  --iui-opacity-2: 0.85;
  --iui-opacity-3: 0.65;
  --iui-opacity-4: 0.45;
  --iui-opacity-5: 0.25;
  --iui-opacity-6: 0.1;
  --iui-color-background: #333c41;
  --iui-color-background-zebra: #374046;
  --iui-color-background-hover: #3a444a;
  --iui-color-background-backdrop: #242b2e;
  --iui-color-background-backdrop-hover: #2c3338;
  --iui-color-background-disabled: #1d2225;
  --iui-color-background-accent: var(--iui-color-background-informational);
  --iui-color-background-accent-hover: var(
    --iui-color-background-informational-hover
  );
  --iui-color-background-accent-muted: var(
    --iui-color-background-informational-muted
  );
  --iui-color-background-informational: #0071b8;
  --iui-color-background-informational-hover: #008ee0;
  --iui-color-background-informational-muted: #384652;
  --iui-color-background-positive: #157e11;
  --iui-color-background-positive-hover: #1e991a;
  --iui-color-background-positive-muted: #3a4b46;
  --iui-color-background-warning: #a05c08;
  --iui-color-background-warning-hover: #c2720f;
  --iui-color-background-warning-muted: #46453f;
  --iui-color-background-negative: #db0a0a;
  --iui-color-background-negative-hover: #f22c2c;
  --iui-color-background-negative-muted: #464347;
  --iui-color-background-transparent-hover: hsla(0deg, 0%, 100%, 0.065);
  --iui-color-border-subtle: #49555d;
  --iui-color-border: #58666f;
  --iui-color-border-foreground: #707070;
  --iui-color-border-foreground-hover: #949494;
  --iui-color-border-disabled: #49555d;
  --iui-color-border-accent: var(--iui-color-border-informational);
  --iui-color-border-informational: #94befa;
  --iui-color-border-positive: #94d293;
  --iui-color-border-warning: #faaa75;
  --iui-color-border-negative: #f9a9a9;
  --iui-color-icon: #c9c9c9;
  --iui-color-icon-hover: #dbdbdb;
  --iui-color-icon-muted: #949494;
  --iui-color-icon-muted-hover: #a6a6a6;
  --iui-color-icon-disabled: #707070;
  --iui-color-icon-accent: var(--iui-color-icon-informational);
  --iui-color-icon-informational: #7bb4f9;
  --iui-color-icon-positive: #82d080;
  --iui-color-icon-warning: #f59e56;
  --iui-color-icon-negative: #f89696;
  --iui-color-text: #dbdbdb;
  --iui-color-text-hover: #ededed;
  --iui-color-text-muted: #b8b8b8;
  --iui-color-text-placeholder: #a6a6a6;
  --iui-color-text-disabled: #828282;
  --iui-color-text-accent: var(--iui-color-text-informational);
  --iui-color-text-accent-hover: var(--iui-color-text-informational-hover);
  --iui-color-text-informational: #94befa;
  --iui-color-text-informational-hover: #bcd4fb;
  --iui-color-text-positive: #94d293;
  --iui-color-text-positive-hover: #d2efd2;
  --iui-color-text-warning: #faaa75;
  --iui-color-text-warning-hover: #fbc9ac;
  --iui-color-text-negative: #f9a9a9;
  --iui-color-text-negative-hover: #fbc6c6;
  --iui-color-background-hsl: 203deg 12% 22.75%;
  --iui-color-foreground-hsl: 0deg 0% 93%;
  --iui-color-accent-hsl: var(--iui-color-informational-hsl);
  --iui-color-informational-hsl: 213deg 91% 73%;
  --iui-color-positive-hsl: 119deg 46% 66%;
  --iui-color-warning-hsl: 27deg 89% 65%;
  --iui-color-negative-hsl: 0deg 87% 78%;
  --iui-shadow-color: hsl(0 0% 0%/var(--iui-opacity-4));
}

[data-iui-theme=dark][data-iui-contrast=high] {
  --iui-opacity-1: 1;
  --iui-opacity-2: 0.85;
  --iui-opacity-3: 0.65;
  --iui-opacity-4: 0.45;
  --iui-opacity-5: 0.25;
  --iui-opacity-6: 0.1;
  --iui-color-background: #333739;
  --iui-color-background-zebra: #383c3f;
  --iui-color-background-hover: #3d4245;
  --iui-color-background-backdrop: #1f2122;
  --iui-color-background-backdrop-hover: #292c2e;
  --iui-color-background-disabled: #141617;
  --iui-color-background-accent: var(--iui-color-background-informational);
  --iui-color-background-accent-hover: var(
    --iui-color-background-informational-hover
  );
  --iui-color-background-accent-muted: var(
    --iui-color-background-informational-muted
  );
  --iui-color-background-informational: #00568f;
  --iui-color-background-informational-hover: #0071b8;
  --iui-color-background-informational-muted: #3c4449;
  --iui-color-background-positive: #0d610a;
  --iui-color-background-positive-hover: #157e11;
  --iui-color-background-positive-muted: #3c4441;
  --iui-color-background-warning: #7c4604;
  --iui-color-background-warning-hover: #a05c08;
  --iui-color-background-warning-muted: #434342;
  --iui-color-background-negative: #a80505;
  --iui-color-background-negative-hover: #db0a0a;
  --iui-color-background-negative-muted: #484244;
  --iui-color-background-transparent-hover: hsla(0deg, 0%, 100%, 0.085);
  --iui-color-border-subtle: #5c6367;
  --iui-color-border: #666e73;
  --iui-color-border-foreground: #999999;
  --iui-color-border-foreground-hover: #b3b3b3;
  --iui-color-border-disabled: #5c6367;
  --iui-color-border-accent: var(--iui-color-border-informational);
  --iui-color-border-informational: #c3deee;
  --iui-color-border-positive: #c8e1c7;
  --iui-color-border-warning: #e8d8c4;
  --iui-color-border-negative: #f9d2d2;
  --iui-color-icon: #d9d9d9;
  --iui-color-icon-hover: #e6e6e6;
  --iui-color-icon-muted: #bfbfbf;
  --iui-color-icon-muted-hover: #cccccc;
  --iui-color-icon-disabled: #a6a6a6;
  --iui-color-icon-accent: var(--iui-color-icon-informational);
  --iui-color-icon-informational: #90c1e0;
  --iui-color-icon-positive: #96c695;
  --iui-color-icon-warning: #d5b690;
  --iui-color-icon-negative: #f2a6a6;
  --iui-color-text: #e6e6e6;
  --iui-color-text-hover: #f2f2f2;
  --iui-color-text-muted: #cccccc;
  --iui-color-text-disabled: #a6a6a6;
  --iui-color-text-accent: var(--iui-color-text-informational);
  --iui-color-text-accent-hover: var(--iui-color-text-informational-hover);
  --iui-color-text-informational: #c3deee;
  --iui-color-text-informational-hover: #ebf4f9;
  --iui-color-text-positive: #c8e1c7;
  --iui-color-text-positive-hover: #ecf4ec;
  --iui-color-text-warning: #e8d8c4;
  --iui-color-text-warning-hover: #f7f0e9;
  --iui-color-text-negative: #f9d2d2;
  --iui-color-text-negative-hover: #fdeded;
  --iui-color-background-hsl: 203deg 6% 21.25%;
  --iui-color-foreground-hsl: 0deg 0% 95%;
  --iui-color-accent-hsl: var(--iui-color-informational-hsl);
  --iui-color-informational-hsl: 203deg 56% 72%;
  --iui-color-positive-hsl: 119deg 30% 68%;
  --iui-color-warning-hsl: 33deg 45% 70%;
  --iui-color-negative-hsl: 0deg 75% 80%;
}

.ITwinSettingsPage_layout {
  display: grid;
  margin: 0 54px 54px;
  grid-template-rows: calc(56px + 12px) minmax(0, 1fr);
  grid-template-areas: "header" "content";
  color: RGBA(var(--iui-text-color-rgb), 0.75);
}
@media screen and (max-width: 768px) {
  .ITwinSettingsPage_layout {
    height: auto;
    margin: 0 calc(54px / 4) calc(54px / 4);
  }
}
.ITwinSettingsPage_layout::after {
  content: "";
  display: block;
  height: 54px;
}
@media screen and (max-width: 768px) {
  .ITwinSettingsPage_layout::after::after {
    height: calc(54px / 4);
  }
}
.ITwinSettingsPage_layout img,
.ITwinSettingsPage_layout video {
  max-width: 100%;
  height: auto;
}
.ITwinSettingsPage_layout .rt-thead {
  background-color: RGBA(var(--iui-color-background-5-rgb), 0.75);
  border-bottom: 0;
}
.ITwinSettingsPage_layout .ReactTable {
  box-shadow: 0 !important;
  border: 0 !important;
}
.ITwinSettingsPage_layout > .ITwinSettingsPage_header {
  display: grid;
  grid-area: header;
  grid-template-columns: 56px minmax(0, 1fr);
  grid-template-areas: "back-button title";
}
.ITwinSettingsPage_layout > .ITwinSettingsPage_header > .ITwinSettingsPage_backButtonWrapper {
  grid-area: back-button;
  align-self: center;
  justify-self: center;
  border-radius: 50%;
  margin: 20px 12px 12px 12px;
}
.ITwinSettingsPage_layout > .ITwinSettingsPage_header > .ITwinSettingsPage_backButtonWrapper > .ITwinSettingsPage_backButton {
  height: 32px;
  width: 32px;
  cursor: pointer;
  fill: var(--iui-color-text);
}
.ITwinSettingsPage_layout > .ITwinSettingsPage_header > .ITwinSettingsPage_title {
  grid-area: title;
  font-weight: 500;
  align-self: center;
  justify-self: left;
  color: var(--iui-color-text);
}
.ITwinSettingsPage_layout > .ITwinSettingsPage_content {
  grid-area: content;
  display: grid;
  grid-template-columns: 200px minmax(0, 1fr);
  grid-template-areas: "tabs current-tab";
  grid-gap: 24px;
}
@media screen and (max-width: 768px) {
  .ITwinSettingsPage_layout > .ITwinSettingsPage_content {
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: 54px minmax(0, 1fr);
    grid-template-areas: "tabs" "current-tab";
  }
}
.ITwinSettingsPage_layout > .ITwinSettingsPage_content > .ITwinSettingsPage_tabs {
  grid-area: tabs;
  background-color: var(--iui-color-background);
  border: 1px solid RGBA(var(--itwin-box-shadow-color-rgb), 1);
}
@media screen and (max-width: 768px) {
  .ITwinSettingsPage_layout > .ITwinSettingsPage_content > .ITwinSettingsPage_tabs {
    background-color: transparent;
  }
}
.ITwinSettingsPage_layout > .ITwinSettingsPage_content > .ITwinSettingsPage_tabs > .ITwinSettingsPage_tabsList {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 768px) {
  .ITwinSettingsPage_layout > .ITwinSettingsPage_content > .ITwinSettingsPage_tabs > .ITwinSettingsPage_tabsList {
    flex: 0 0 auto;
    flex-direction: row;
    align-items: stretch;
    align-content: stretch;
    justify-content: stretch;
  }
}
.ITwinSettingsPage_layout > .ITwinSettingsPage_content > .ITwinSettingsPage_currentTab {
  grid-area: current-tab;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 2px;
  background-color: var(--iui-color-background);
  border: 1px solid RGBA(var(--itwin-box-shadow-color-rgb), 1);
  box-sizing: border-box;
  padding: 12px;
}
@media screen and (max-width: 768px) {
  .ITwinSettingsPage_layout > .ITwinSettingsPage_content > .ITwinSettingsPage_currentTab {
    background-color: transparent;
  }
}
.ITwinSettingsPage_layout > .ITwinSettingsPage_content > .ITwinSettingsPage_currentTab > .ITwinSettingsPage_currentTabTop {
  background-color: var(--iui-color-background);
  padding: 12px;
  grid-column: 1/-1;
}
.ITwinSettingsPage_layout > .ITwinSettingsPage_content > .ITwinSettingsPage_currentTab > .ITwinSettingsPage_currentTabLeft,
.ITwinSettingsPage_layout > .ITwinSettingsPage_content > .ITwinSettingsPage_currentTab > .ITwinSettingsPage_currentTabRight {
  box-sizing: border-box;
  padding: 24px;
  background-color: var(--iui-color-background);
}
@media screen and (max-width: 768px) {
  .ITwinSettingsPage_layout > .ITwinSettingsPage_content > .ITwinSettingsPage_currentTab > .ITwinSettingsPage_currentTabLeft,
  .ITwinSettingsPage_layout > .ITwinSettingsPage_content > .ITwinSettingsPage_currentTab > .ITwinSettingsPage_currentTabRight {
    padding: 12px;
  }
}
@media screen and (max-width: 11.4in) {
  .ITwinSettingsPage_layout > .ITwinSettingsPage_content > .ITwinSettingsPage_currentTab {
    display: block;
    position: relative;
  }
}
.ITwinSettingsPage_layout > .ITwinSettingsPage_content > .ITwinSettingsPage_currentTabWithAlert {
  grid-area: current-tab;
  display: grid;
  grid-template-areas: "alert" "subsection";
  grid-template-rows: minmax(0, 1fr);
}
.ITwinSettingsPage_layout > .ITwinSettingsPage_content > .ITwinSettingsPage_currentTabWithAlert > .ITwinSettingsPage_alert {
  grid-area: alert;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
}
.ITwinSettingsPage_layout > .ITwinSettingsPage_content > .ITwinSettingsPage_currentTabWithAlert > .ITwinSettingsPage_subsection {
  grid-area: subsection;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 1px;
  background-color: RGBA(var(--itwin-box-shadow-color-rgb), 1);
  border: 1px solid RGBA(var(--itwin-box-shadow-color-rgb), 1);
}
@media screen and (max-width: 768px) {
  .ITwinSettingsPage_layout > .ITwinSettingsPage_content > .ITwinSettingsPage_currentTabWithAlert > .ITwinSettingsPage_subsection {
    grid-gap: 0;
  }
}
.ITwinSettingsPage_layout > .ITwinSettingsPage_content > .ITwinSettingsPage_currentTabWithAlert > .ITwinSettingsPage_subsection > .ITwinSettingsPage_currentTabLeft,
.ITwinSettingsPage_layout > .ITwinSettingsPage_content > .ITwinSettingsPage_currentTabWithAlert > .ITwinSettingsPage_subsection > .ITwinSettingsPage_currentTabRight {
  background-color: var(--iui-color-background);
  padding: 24px;
  padding-top: 54px;
}
@media screen and (max-width: 768px) {
  .ITwinSettingsPage_layout > .ITwinSettingsPage_content > .ITwinSettingsPage_currentTabWithAlert > .ITwinSettingsPage_subsection > .ITwinSettingsPage_currentTabLeft,
  .ITwinSettingsPage_layout > .ITwinSettingsPage_content > .ITwinSettingsPage_currentTabWithAlert > .ITwinSettingsPage_subsection > .ITwinSettingsPage_currentTabRight {
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media screen and (max-width: 11.4in) {
  .ITwinSettingsPage_layout > .ITwinSettingsPage_content > .ITwinSettingsPage_currentTabWithAlert > .ITwinSettingsPage_subsection {
    display: block;
    position: relative;
  }
}
.ITwinSettingsPage_layout .ITwinSettings_Display_ThemeSwitch {
  display: inline-flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
}
.ITwinSettingsPage_layout .ITwinSettings_Display_ThemeSwitch > .ITwinSettings_Display_ThemeLabel {
  padding-right: 10px;
  padding-left: 10px;
}
.ITwinSettingsPage_layout .ITwinSettings_Display_ThemeSwitch > .core-toggle.rounded {
  height: 20px !important;
  width: 40px !important;
}
.ITwinSettingsPage_layout .ITwinSettings_Display_StartupSwitch {
  display: inline-flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
}
.ITwinSettingsPage_layout .ITwinSettings_Display_StartupSwitch > .ITwinSettings_Display_BingMapLabel,
.ITwinSettingsPage_layout .ITwinSettings_Display_StartupSwitch .ITwinSettings_Display_TerrainLabel,
.ITwinSettingsPage_layout .ITwinSettings_Display_StartupSwitch .ITwinSettings_Display_OSMLabel {
  padding-right: 10px;
  padding-left: 10px;
}
.ITwinSettingsPage_layout .ITwinSettings_Display_StartupSwitch > .core-toggle.rounded {
  height: 20px !important;
  width: 40px !important;
}