.propertyGridButton {
  display: flex;
  justify-content: center;
  color: black;
  margin-left: 8px;
}

.pt-3 {
  padding-top: 0px;
}

p {
  margin-bottom: 0px;
}

.hrColor {
  opacity: 0.15;
  margin: 0.5rem;
  padding-left: 8px;
}

.property-button {
  color: white;
  font-weight: 500;
  background: #199fd8;
  border-radius: 4px;
}

.property-button-disabled {
  color: white;
  font-weight: 500;
  background: #9ba5ae;
  border-radius: 4px;
}

.property-button:disabled {
  color: white;
  font-weight: 500;
  background: #9ba5ae;
  border-radius: 4px;
}