.noDataListContainer {
  width: 100%;
  display: table;
  height: calc(100% - 48px);
  text-align: center;
}

.noDataListText {
  font-size: 16px;
  display: table-cell;
  vertical-align: middle;
  color: lightgray;
}

.header {
  color: #199fd8;
  font-size: 20px;
  padding-top: 12px;
  padding-Left: 10px;
  text-align: center;
}

.components-property-label-renderer, .components-property-record-value span {
  font-weight: 600;
}

.components-property-record--vertical .components-property-record-value, .components-property-record--vertical .components-property-record-label {
  margin: 0 20px;
}

.nz-stagePanels-stagePanels .components-property-record--vertical {
  margin: 0px !important;
  border-bottom: 1px solid #d8d6d6;
}

.nz-panel-right {
  overflow-y: auto;
}

.info-panel {
  overflow-y: auto;
  height: 100%;
}
.info-panel .header .pin-header {
  margin-bottom: 0px;
}
.info-panel .info-panel-images > .slider {
  height: 200px;
  width: 230px;
  margin: auto;
  margin-bottom: 8px;
  margin-top: 8px;
}
.info-panel .info-panel-images .image {
  width: 230px;
  height: 200px;
}
.info-panel .info-panel-images .right-arrow {
  margin-right: 4px;
  transform: translate(0%, -50%);
}
.info-panel .info-panel-images .left-arrow {
  margin-left: 4px;
  transform: translate(0%, -50%);
}

.info-panel .header {
  margin-bottom: 5px;
  text-align: center;
  font-size: 20px;
  padding: 13px;
  border-bottom: 2px solid #e8e8e8;
}

.empty-list {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.column-spacing {
  padding-left: 4px !important;
  font-size: 14px;
  font-weight: 600;
}

.square {
  height: 15px;
  width: 15px;
}

.save-button {
  background-color: #56aa1c;
  color: #fff;
  display: inline-block;
  padding: 6px 18px;
  margin: 0 16px 0 0;
  border-radius: 3px;
  line-height: 22px;
  outline: none;
  box-shadow: none;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #dce0e3;
}

.save-button:disabled {
  background-color: grey;
  color: #fff;
  display: inline-block;
  padding: 6px 18px;
  margin: 0 16px 0 0;
  border-radius: 3px;
  line-height: 22px;
  outline: none;
  box-shadow: none;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #dce0e3;
}

/*.btn-wrapper {
    top: 50%;
    left: 80%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}*/
.cancel-button {
  padding: 5px px 15px;
  border: 1px solid #85929d;
  background-color: initial;
  color: #3c434a;
  line-height: 22px;
  outline: none;
  box-shadow: none;
  text-decoration: none;
  cursor: pointer;
  margin-right: 1rem;
}

.float-div {
  background-color: ghostwhite;
  position: absolute;
  bottom: 0;
  width: -webkit-fill-available;
  margin-left: -6px;
}

.float-div button {
  margin-bottom: 15px;
}

.dropbtn {
  background-color: #34aadd;
  padding: 2px 10px;
  border: none;
  margin-top: 5px;
  margin-left: 0px;
  margin-right: 5px;
  border-radius: 5px 5px;
  font-weight: 600;
  font-size: 13.5px;
  color: #363636;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 80px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  left: -10px;
}

.dropdown-content a {
  color: black;
  padding: 10px 10px;
  width: auto;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #a8dbf0;
}

.plc {
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.plc:hover {
  opacity: 1;
  background-color: #e5f3fc;
  font-size: initial;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  /*    animation-name: right;
  animation-duration: 1.1s;*/
}

/*.fullPinList {
    animation-name: right;
    animation-duration: 2.1s;
}*/
.propInfo {
  animation: linear;
  animation-name: leftX;
  animation-duration: 0.2s;
  position: relative;
}

/*.pfoe :hover {
    animation: linear;
    animation-name: example;
    animation-duration: 0.2s;
    background-color: blue;
    margin-top: -3px;
    margin-bottom: -3px
}
.pfo :hover {
    animation: linear;
    animation-name: example;
    animation-duration: 1.2s;
    background-color: aqua;
    positon: relative;

}*/
/*.pfo {
    animation: linear;
    animation-name: leftX;
    animation-duration: 2.2s;
    animation-name: leftX;
    animation-duration: 1.2s;
    position: relative;
}*/
#pin-backButton:hover {
  color: #3e7dff;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

#pintag-editbutton:hover {
  background-color: #5b8ff8;
  color: #3e7dff;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  border: dashed;
}

#pintag-editbutton {
  padding: 0;
  border-radius: 20px;
}

#pin-searchBar input[type=text] {
  width: 0px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 25px;
  font-size: 13px;
  padding: 6px 10px 6px 20px;
  -webkit-transition: width 0.24s ease-in-out;
  transition: width 0.24s ease-in-out;
  position: relative;
  margin-right: 195px;
  margin-bottom: 7px;
  z-index: 10;
  background-image: url("./~@bentley/svg-icons/icons/search.svg");
  background-color: white;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 17px;
}

#pin-searchBar input[type=text]:focus {
  border: 1px thin #ccc;
  width: 50%;
  background-blend-mode: lighten;
}

#pin-searchBar {
  position: relavtive;
  float: right;
  padding-top: -20px;
  width: 100%;
}

/*#holder.hover {
    border: 4px dashed #0c0 !important;
}*/
/*#holder:hover {
    border: 4px dashed #0c0 !important;
}*/
/*#holder p {
    text-align: center;
    color: darkgray;
    font-weight: initial;
    padding-top: 6px;
}*/
#holder {
  width: -webkit-fill-available;
  height: 100%;
  /* text-align: center; */
  /* border: 0px dashed #808080 !important; */
  margin-top: -5px;
  margin-left: 19px;
  border-radius: 10px 10px;
  position: relative;
}

holder a:link {
  float: left;
}

.dropimage_text {
  text-align: center;
}

.elc {
  float: right;
  margin-top: -41px;
}

.ddf {
  font-size: 10px;
  background-color: cornflowerblue;
  border-radius: 4px 4px;
  width: 64px;
  height: 14px;
  display: inline-block;
  word-wrap: break-word;
  overflow: hidden;
  position: relative;
}

.ddf:hover {
  font-size: 10px;
  background-color: red;
  border-radius: 4px 4px;
  width: 64px;
  height: 14px;
  display: inline-block;
  word-wrap: break-word;
  overflow: hidden;
  position: relative;
}

.annotationOption {
  display: inline-block;
  padding: 1.3px 7px;
  margin: 4.4px 0;
  font-size: 14px;
  line-height: 15.4px;
  color: var(--buic-foreground-body);
  background-color: var(--buic-background-control);
  background-image: none;
  border: 1px solid var(--buic-inputs-border);
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: var(--buic-inputs-boxshadow) 0 1px 1px inset;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  width: -webkit-fill-available;
  padding: 8px;
  margin: 8px;
}

#projShareOptions {
  float: right;
  margin-right: 30px;
  margin-top: -29px;
  position: relative;
}

.disc-text {
  /*overflow: hidden;*/
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  display: table-cell;
  vertical-align: middle;
  margin-bottom: 0;
  margin-left: 20;
}

#comment-title {
  position: relative;
  margin-left: 0px;
  display: block;
  padding: 19px;
  margin-bottom: -10px;
  text-align: justify;
}

#comment-text {
  background-image: url("./pintagimages/upload-images.png");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 2px 2px;
  margin-left: 20px;
  width: -webkit-fill-available;
  /*opacity: 0.5;*/
}

#sub-title {
  position: relative;
  margin-left: 0px;
  display: block;
  padding: 19px;
  margin-bottom: -10px;
  text-align: justify;
}

#sub-text {
  border-radius: 2px 2px;
  margin-left: 20px;
  width: -webkit-fill-available;
}

#link-proj-files {
  border: none;
  border-radius: 20px 2px;
}

#link-proj-img {
  border: none;
  border-radius: 20px 2px;
}

#link-proj-ext {
  border: none;
  border-radius: 20px 2px;
}

#link-proj-img:hover {
  border: none;
  border-radius: 20px 2px;
  /*background-color: #5b8ff8;*/
  color: #3e7dff;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

#link-proj-files:hover {
  border: none;
  border-radius: 20px 2px;
  /*background-color: #5b8ff8;*/
  color: #3e7dff;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

#link-proj-ext:hover {
  border: none;
  border-radius: 20px 2px;
  /*background-color: #5b8ff8;*/
  color: #3e7dff;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

#pshare-back-button {
  background-image: url("./~@bentley/svg-icons/icons/refresh.svg");
  background-color: white;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  border-radius: 66px 2px;
  margin-left: 63px;
  width: 40px;
  height: 22px;
  position: relative;
  top: 10px;
  margin-right: 2px;
}

.psc-folder-img {
  background-image: url("./pintagimages/folder.png");
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: 30px 30px;
  background-position: center;
  position: relative;
}

.psc-folder-imgxml {
  background-image: url("./pintagimages/xml.png");
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: 30px 30px;
  background-position: center;
  position: relative;
}

.psc-folder-imgpdf {
  background-image: url("./pintagimages/pdf.png");
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: 30px 30px;
  background-position: center;
  position: relative;
}

.psc-folder-imgany {
  background-image: url("./pintagimages/any.png");
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: 30px 30px;
  background-position: center;
  position: relative;
}

.psc-folder-imgimg {
  background-image: url("./pintagimages/image.png");
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: 30px 30px;
  background-position: center;
  position: relative;
}

.psc-folder-imgzip {
  background-image: url("./pintagimages/zip.png");
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: 30px 30px;
  background-position: center;
  position: relative;
}

.psc-holder {
  display: -webkit-inline-box;
  width: -webkit-fill-available;
}

.psc {
  left: 7px;
  top: 9px;
  position: relative;
}

#ddfl-button {
  margin-left: 5px;
  background-image: url("./pintagimages/recycle-bin.png");
  background-color: white;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  width: 0px;
  height: 11px;
  background-size: 93% 100%;
}

#pscl-button {
  margin-left: 5px;
  background-image: url("./pintagimages/recycle-bin.png");
  background-color: white;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  width: 0px;
  height: 11px;
  background-size: 93% 100%;
}

.e-link {
  font-weight: 400;
  margin-left: 20px;
  font-size: 12px;
}

.p-links-title {
  font-weight: 400;
  margin-left: 20px;
  font-size: 12px;
}

.pli-list {
  white-space: nowrap;
  overflow: hidden;
}

#rot-icon-id {
  background-image: url("./~@bentley/svg-icons/icons/chevron-right.svg");
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  height: 11px;
  width: 11px;
  border: none;
  margin-left: 5px;
  top: 1px;
  position: relative;
  transform: rotateZ(0deg);
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

#rot-icon-id-rotated {
  background-image: url("./~@bentley/svg-icons/icons/chevron-right.svg");
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  height: 11px;
  width: 11px;
  border: none;
  margin-left: 5px;
  top: 1px;
  position: relative;
  transform: rotateZ(90deg);
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

#rot-icon-id :hover {
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

#link-proj-ext {
  border: none;
  border-radius: 20px 2px;
}

#link-proj-img:hover {
  border: none;
  border-radius: 20px 2px;
  /*background-color: #5b8ff8;*/
  color: #3e7dff;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

#link-proj-files:hover {
  border: none;
  border-radius: 20px 2px;
  /*background-color: #5b8ff8;*/
  color: #3e7dff;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

@keyframes rightX {
  from {
    right: 0px;
  }
  to {
    right: -300px;
  }
}
@keyframes leftX {
  0% {
    left: 300px;
  }
  100% {
    left: 0%;
  }
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.label-mount-footer {
  font-weight: lighter;
  font-size: small;
}

.dialogBox-mount-Cancel-btn {
  padding: 5px 15px;
  border: 1px solid #85929d;
  background-color: initial;
  color: #3c434a;
  line-height: 22px;
  outline: none;
  box-shadow: none;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  margin-right: 1rem;
}

div .property-editor-dialog:has(::before) {
  font-size: 15px;
  font-weight: bold;
}

.dialogBox-input {
  display: block;
  width: 50%;
  padding: 7px 10px;
  margin: 3px 0;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  max-width: 375px;
  margin-bottom: 14px;
  color: #2a2f34;
  background-color: #fff;
  background-image: none;
  border: 1px solid #c7ccd1;
  border-radius: 3px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-right: 1rem;
}
.dialogBox-input:hover:not(:disabled) {
  border-color: rgb(77, 87, 95);
  background-color: #ebebeb;
  border-color: rgba(155, 165, 174, 0.4);
}
.dialogBox-input:disabled {
  background-color: rgba(155, 165, 174, 0.4) !important;
  border-color: rgba(155, 165, 174, 0.4) !important;
  font-weight: 400;
  color: #FFFFFF;
  cursor: not-allowed;
}

.dialogBox-dropdown {
  border: 1px solid #c7ccd1;
  border-radius: 5px;
  height: 38px;
  width: 50%;
  margin-bottom: 10px;
  margin-right: 1rem;
}

.dialogBox-dropdown-heading {
  padding: 0;
  margin: 0;
  border: 0 transparent;
  display: block;
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #2a2f34;
  flex-grow: 5;
  align-items: center;
  display: flex;
}

.dialogBox-heading-coloun {
  align-items: center;
  display: flex;
  flex-grow: 5;
  margin-right: 1rem;
  justify-content: space-between;
}

.dialogBox-save-btn {
  background-color: #56aa1c;
  border-color: #56aa1c;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  padding: 5px 18px;
  margin: 0 16px 0 0;
  border-radius: 3px;
  line-height: 22px;
  outline: none;
  box-shadow: none;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #dce0e3;
}
.dialogBox-save-btn:disabled {
  border-style: solid;
  border-width: 1px;
  opacity: 1;
  border-style: none !important;
  cursor: not-allowed;
  background-color: #9ba5ae;
}

.dialogBox-cancel-btn {
  padding: 5px 15px;
  border: 1px solid #85929d;
  border-radius: 5px;
  background-color: initial;
  color: #3c434a;
  line-height: 22px;
  outline: none;
  box-shadow: none;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  margin-right: 1rem;
}

.edit-mount-btn {
  text-decoration: none;
  border: none;
  padding: 4px;
  width: 80%;
  font-size: 15px;
  background-color: #199fd8;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  transition: 0.2s all;
  margin-top: 3px;
  margin-bottom: 10px;
}

.edit-mount-container {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #d8d6d6;
}

.dialogBox-propEditModal-Cancel-btn {
  padding: 5px 15px;
  border: 1px solid #85929d;
  background-color: initial;
  color: #3c434a;
  line-height: 22px;
  outline: none;
  box-shadow: none;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  margin-right: 1rem;
}

div .propEditModal-dialog:has(::before) {
  font-size: 15px;
  font-weight: bold;
}

.dialogBox-propEditModal-input {
  display: block;
  width: 50%;
  padding: 7px 10px;
  margin: 3px 0;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  max-width: 375px;
  margin-bottom: 14px;
  color: #2a2f34;
  background-color: #fff;
  background-image: none;
  border: 1px solid #c7ccd1;
  border-radius: 3px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-right: 30px;
  margin-top: 4px;
  margin-bottom: 4px;
  width: 200px;
  float: right;
  margin-right: 30px;
}
.dialogBox-propEditModal-input:hover:not(:disabled) {
  border-color: rgb(77, 87, 95);
  background-color: #ebebeb;
  border-color: rgba(155, 165, 174, 0.4);
}
.dialogBox-propEditModal-input:disabled {
  background-color: rgba(155, 165, 174, 0.4) !important;
  border-color: rgba(155, 165, 174, 0.4) !important;
  font-weight: 400;
  color: #FFFFFF;
  cursor: not-allowed;
}

.filter-propEditModal-dropdown {
  border: 1px solid #c7ccd1;
  border-radius: 5px;
  height: 38px;
  width: 50%;
  margin-bottom: 10px;
  max-width: 100% !important;
  margin-top: 4px;
  margin-bottom: 4px;
  float: right;
  margin-right: 30px;
  padding-left: 6px !important;
}

.dialogBox-propEditModal-dropdown {
  border: 1px solid #c7ccd1;
  border-radius: 5px;
  height: 38px;
  width: 50%;
  max-width: 375px;
  margin-bottom: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
  float: right;
  margin-right: 30px;
  padding-left: 6px !important;
}

.dialogBox-propEditModal-dropdown-heading {
  padding: 0;
  margin: 0;
  border: 0 transparent;
  display: block;
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #2a2f34;
  flex-grow: 5;
  align-items: center;
  display: flex;
}

.dialogBox-propEditModal-heading-coloumn {
  align-items: center;
  display: flex;
  flex-grow: 5;
  justify-content: space-between;
}

.dialogBox-propEditModal-save-btn {
  background-color: #56aa1c;
  border-color: #56aa1c;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  padding: 6px 18px;
  margin: 0 16px 0 0;
  line-height: 22px;
  outline: none;
  box-shadow: none;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #dce0e3;
}
.dialogBox-propEditModal-save-btn:disabled {
  border-style: solid;
  border-width: 1px;
  opacity: 1;
  border-style: none !important;
  cursor: not-allowed;
  background-color: #9ba5ae;
}

.dialogBox-propEditModal-cancel-btn {
  padding: 5px 15px;
  border: 1px solid #85929d;
  border-radius: 5px;
  background-color: initial;
  color: #3c434a;
  line-height: 22px;
  outline: none;
  box-shadow: none;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  margin-right: 1rem;
}

.edit-propEditModal-btn {
  text-decoration: none;
  border: none;
  padding: 4px;
  width: 80%;
  font-size: 15px;
  background-color: #199fd8;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  transition: 0.2s all;
  margin-top: 3px;
  margin-bottom: 10px;
}

.edit-propEditModal-container {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #d8d6d6;
}

.center-propEditModal-label {
  max-width: 100%;
}

.heading-propEditModal {
  color: #199fd8;
  z-index: 101;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.propEditModal-modal-dialog > div > div .core-dialog-container > .core-dialog-area > .core-dialog-head > .core-dialog-title {
  font-size: 15px !important;
  font-weight: 501 !important;
  padding: 5px 0 5px 0;
}

.propEditModal-modal-dialog > div > div .core-dialog-container > .core-dialog-area > .core-dialog-content {
  background-color: #FFFFFF;
}

.propEditModal-modal-dialog > div > div .core-dialog-container > .core-dialog-area > .core-dialog-head {
  background-color: #dde1e4;
}

/*---------------------------------------------------------------*/
.tow-marker-highlight {
  border: 1px solid rgb(138, 138, 151);
  border-left: none;
  border-right: none;
  background: lightgrey;
}

.tower-marker:hover {
  text-decoration: underline;
  cursor: pointer;
}

.tower-marker div > div.square {
  margin-right: 0px;
}

.tr-style {
  border: 2px solid darkseagreen;
  padding-left: 10px;
  display: table-caption;
}

.RadDialogContent {
  margin-bottom: -10px;
}

div #defect-modal-container {
  display: block;
}

._iui3163-input-grid {
  display: flex;
  width: 100%;
}
._iui3163-input-grid div {
  margin-right: 10px;
  width: 100%;
}
._iui3163-input-grid div input {
  background-color: #fff;
  background-image: none;
  border: 1px solid #c7ccd1;
  border-radius: 3px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.0784313725);
  color: #2a2f34;
  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  margin: 3px 10px 8px 0;
  padding: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
._iui3163-input-grid label {
  width: 100%;
}

._iui3-dialog-content {
  overflow-y: visible !important;
}

.uifw-dialog-dialogManagerBase_renderer > div {
  z-index: 99999 !important;
}