.dtv-devtools-widget {
  overflow-y: auto;
  height: 100%;
}

.dtv-devtools-buttons {
  display: flex;
  margin-left: auto;
  margin-right: 0;
}

.dtv-devtools-button {
  margin-right: 10px;
}

.dtv-devtools-input {
  margin-right: 10px;
  width: 25%;
}

.dtv-devtools-container {
  border: solid;
  overflow: auto;
  border-color: #808080;
  border-width: 1px;
}

.dtv-devtools-flex {
  display: flex;
  border: solid;
  border-color: #d3d3d3;
  border-width: 1px;
}

.dtv-devtools-text-bold {
  font-weight: bold;
  width: 30%;
}

.dtv-devtools-text {
  text-align: left;
}

.dtv-devtools-text-small {
  text-align: left;
  font-size: 11px;
  overflow-wrap: anywhere;
}

.dr-dev-overflow {
  overflow-y: auto;
  height: 100%;
}

.dr-tiles-info-container {
  overflow: auto;
  border: solid;
  border-color: #808080;
  border-width: 1px;
  padding: 4px;
}

.dr-dev-ff-button {
  display: flex;
  flex-grow: 1;
  margin: 4px;
}

.dtv-devtools-ff-container {
  height: 85%;
  border: solid;
  overflow: auto;
  border-color: #808080;
  border-width: 1px;
}
.dtv-devtools-ff-container > .table {
  table-layout: fixed;
  width: 100%;
}

.dtv-devtools-ff-widget {
  overflow-y: auto;
  height: 100%;
}

.dtv-devtools-ff-flag {
  display: flex;
}

.dtv-devtools-ff-flag-on {
  color: #ffffff;
  background-color: #008000;
  font-weight: bold;
  border-radius: 3px;
  text-align: center;
}

.dtv-devtools-ff-flag-off {
  color: #000000;
  background-color: #808080;
  font-weight: bold;
  border-radius: 3px;
  text-align: center;
}

.dtv-devtools-ff-flag-na {
  color: #808080;
  background-color: #2f4f4f;
  font-weight: bold;
  border-radius: 3px;
  text-align: center;
}

.dtv-devtools-ff-flag-override {
  color: #000000;
  background-color: #ffa500;
  font-weight: bold;
  border-radius: 3px;
  text-align: center;
}

tr:nth-child(even) {
  background-color: #add8e6;
}

tr {
  text-align: left;
}

.dtv-devtools-ff-flag-toggle {
  cursor: pointer;
  border-radius: 3px;
  background-color: #add8e6;
  font-weight: bold;
  border-style: solid;
  border-width: 1px;
  text-align: center;
  border: 1px;
}

.dtv-devtools-ff-flag-toggle:hover {
  background-color: #008b8b;
}