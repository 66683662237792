.imageViewModel {
    pointer-events: auto;
}


.header {
    text-align: center;
}

.carousel-container {
    margin: 20px;
}

.selected-image {
    width: 100%;
    height: 500px;
    margin-bottom: 8px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.carousel {
    position: relative;
}

.carousel__images {
    display: flex;
    max-width: 100%;
    overflow-x: hidden;
}

.carousel__image-selected {
    border: 3px solid #ffa700 !important;
}

.carousel__image {
    margin-right: 10px;
    height: 80px;
    min-width: 150px;
    border: 3px solid #ffa70000;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.carousel__button {
    position: absolute;
    top: 40%;
}

.carousel__button-left {
    left: 10px;
}

.carousel__button-right {
    right: 10px;
}

.core-dialig-content{
    background-color: black;
}

.navigation > ul {
    /* border: 1px solid rgb(212, 20, 20); */
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    color: rgb(0, 0, 0);
    flex-direction: row;
    justify-content: space-around;
    /* background-color: #EEEEEE; */
    margin-bottom: 0.5rem;
    cursor: pointer;
 }


.navigation > ul li {
    display: block;
    color: rgb(0, 0, 0);
    font-weight:bold;
    padding: 8px 16px;
    text-decoration: none;
    cursor: pointer;
}

.navigation .active-class{
    background-color: #257AFD;
    border-radius: 0.5rem;
    color: white;
}
.carousel-inner > .item > img, .carousel-inner > .item > a > img {
    width: 100%;
}

.carousel-control-next{
    background-color: #ffffff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    right: 20px;
    top: 43%;
}

.carousel-control-prev{
    background-color: #ffffff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    left: 20px;
    top: 43%;
}

.accordion-header button {
    padding: 10px 0;
}