.pin-tag-popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.pin-tag-popup .popuptext {
  visibility: hidden;
  width: 150%;
  height: fit-content;
  background-color: #b9bec3;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -225px;
  border-style: solid;
  border-width: unset;
}

.spswrp {
  margin-left: 6px;
  margin-right: 6px;
  overflow-y: auto;
  height: 36.5px;
  overflow-x: auto;
}

.dlinkwrp {
  font-size: xx-small;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 6px;
  margin-right: 6px;
  overflow-y: auto;
  height: 44px;
}

.plink {
  margin-right: 6px;
}

.subspan-img {
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  margin-left: 4px;
  border-style: solid;
  border-width: thin;
  width: 30px;
  height: 30px;
  background-size: contain;
}

/* Popup arrow */
.pin-tag-popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
  margin-top: 2px;
}

/* Toggle this class - hide and show the popup */
.pin-tag-popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
}

#popup-cancel {
  z-index: 3;
  height: 8px;
  width: 8px;
  opacity: 0.5;
  margin-left: -23px;
  margin-right: 8px;
  float: right;
  background-image: url("./~@bentley/svg-icons/icons/close.svg");
  background-color: #b9bec3;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  background-position: center;
}

#popup-cancel :hover {
  z-index: 3;
  height: 8px;
  width: 8px;
  opacity: 1;
  margin-left: -23px;
  margin-right: 8px;
  float: right;
  background-image: url("./~@bentley/svg-icons/icons/close.svg");
  color: #3e7dff;
  background-color: #3e7dff;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}