#EditDialogComponentId > div > div {
  left: 260px !important;
  top: 230px !important;
  width: 550px;
  height: 380px;
}

.editDialogCloseButton {
  color: red;
  border: none;
  right: 10px;
  position: absolute;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
  opacity: 0.3;
}

.editDialogCloseButton:hover {
  opacity: 1;
}