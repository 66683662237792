.error-canvas {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #eef0f3;
}
.error-canvas .error-box {
  position: absolute;
  width: 500px;
  z-index: 15;
  top: 25%;
  left: 50%;
  margin: -100px 0 0 -250px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid RGBA(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  box-shadow: 0 3px 9px RGBA(0, 0, 0, 0.5);
}
.error-canvas .error-box .error-title {
  color: #cc0000;
  fill: #cc0000;
  border-color: #f7d9d9;
  background-color: #fae5e5;
  padding: 30px;
  font-size: 20px;
  text-align: center;
}
.error-canvas .error-box .nav-button {
  text-decoration: none !important;
  box-shadow: 0 1px 5px RGBA(0, 0, 0, 0.18);
  margin: 13px;
  display: block;
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
  padding: 6px 12px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}