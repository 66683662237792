.loadingTableContainer {
  width: 100%;
  display: table;
  height: 100%;
  text-align: center;
}

.loadingTableText {
  font-size: 42px;
  display: table-cell;
  vertical-align: middle;
  color: lightgray;
}

.css-xb97g8 {
  display: none !important;
}

.css-1hb7zxy-IndicatorsContainer {
  height: 22px !important;
}

.react-select__multi-value__label {
  max-height: 24px;
  top: 50%;
  position: absolute;
  box-sizing: border-box;
  transform: translateY(-50%);
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border: 1px rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
  border-radius: 10px;
}

.EquipmentPropsWidget, .DefectPropsWidget {
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 33px;
  padding-top: 10px;
}

.nz-content-container {
  min-height: 280px !important;
}

.nz-indicator {
  width: 103px;
  margin-top: 5px;
}

.core-lb {
  padding-bottom: 9px !important;
}