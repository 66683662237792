fieldset {
  display: block;
  margin-top: 18px;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
  border-width: 2px;
  border-style: groove;
  border-color: rgb(192, 192, 192);
  border-image: initial;
}

legend {
  color: black;
  font: 12px "Noto Sans", "Open Sans";
  padding: 0px 2px;
  margin-top: -14px;
  background: white none repeat scroll 0 0;
  width: auto;
}