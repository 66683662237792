.delete-mount-container {
  margin: 10px;
  padding: 10px;
}

.delete-mount-heading {
  margin-bottom: 15px;
}

.delete-mount-save-btn {
  background-color: #008be1;
  border: none;
  color: #fff;
  border: 1px solid #dce0e3;
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 22px;
  margin: 0 16px 0 0;
  outline: none;
  padding: 8px 18px;
  text-decoration: none;
}

.delete-mount-cancel-btn {
  background-color: transparent;
  border: 1px solid #dce0e3;
  border-radius: 3px;
  box-shadow: none;
  color: #3c434a;
  cursor: pointer;
  display: inline-block;
  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 22px;
  margin: 0 16px 0 0;
  outline: none;
  padding: 8px 11px;
  text-decoration: none;
}